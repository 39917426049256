@import '@arco-themes/react-arco-pro/index.less';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
}

.chart-wrapper {
  .bizcharts-tooltip {
    background: linear-gradient(
      304.17deg,
      rgb(253 254 255 / 60%) -6.04%,
      rgb(244 247 252 / 60%) 85.2%
    ) !important;
    border-radius: 6px;
    backdrop-filter: blur(10px);
    padding: 8px !important;
    width: 180px !important;
    opacity: 1 !important;
  }
}

body[arco-theme='dark'] {
  .chart-wrapper {
    .bizcharts-tooltip {
      background: linear-gradient(
        304.17deg,
        rgba(90, 92, 95, 0.6) -6.04%,
        rgba(87, 87, 87, 0.6) 85.2%
      ) !important;
      backdrop-filter: blur(10px);
      border-radius: 6px;
      box-shadow: none !important;
    }
  }
}


// 分组标题(带有蓝色竖杠的标题)
.group_title{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 20px;
  color: #181826;
  margin-bottom: 20px;
  &::before{
    content: "";
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #1E3CFF;
    margin-right: 12px;
    margin-top: 1px;
  }
}


.form_item_title {
  display: flex;
  align-items: center;
  line-height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgb(78, 89, 105);
  margin-bottom: 8px;
  i {
    color: #ed2231;
    margin-right: 5px;
    height: 14px;
  }
}

.form_item_text {
  display: flex;
  align-items: center;
  height: 26px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #181826;
  background-color: var(--color-fill-2);
  padding: 4px 12px;
  border-radius: 4px;
}

.form_item_content {
  min-height: 32px;
  display: flex;
  align-items: center;
}
